<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">
      <b-card class="mb-0">

        <!-- logo -->
        <b-link class="brand-logo">
          <!-- logo -->
          <b-img :src="logo" width="200"/>
        </b-link>

        <b-card-title class="mb-1">
          Redefinir Senha 🔒
        </b-card-title>
        <b-card-text class="mb-2">
          Sua senha deve ser diferente das últimas senhas utilizadas
        </b-card-text>

        <!-- form -->
        <validation-observer ref="simpleRules">
          <b-form
            method="POST"
            class="auth-reset-password-form mt-2"
            @submit.prevent="validationForm"
          >

            <!-- password -->
            <b-form-group
              label="Nova"
              senha-
              label-for="reset-password-new"
            >
              <validation-provider
                #default="{ errors }"
                name="Senha"
                vid="senha"
                rules="required|min:6|password"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <b-form-input
                    id="reset-password-new"
                    v-model="password"
                    :type="password1FieldType"
                    :state="errors.length > 0 ? false:null"
                    class="form-control-merge"
                    name="reset-password-new"
                    placeholder="············"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="password1ToggleIcon"
                      @click="togglePassword1Visibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- confirm password -->
            <b-form-group
              label-for="reset-password-confirm"
              label="Confirme sua senha"
            >
              <validation-provider
                #default="{ errors }"
                name="Confirme a sua senha"
                vid="Confirme a sua senha"
                rules="required|confirmed:senha|password"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <b-form-input
                    id="reset-password-confirm"
                    v-model="confirm_password"
                    :type="password2FieldType"
                    class="form-control-merge"
                    :state="errors.length > 0 ? false:null"
                    name="reset-password-confirm"
                    placeholder="············"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="password2ToggleIcon"
                      @click="togglePassword2Visibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- submit button -->
            <b-button
              block
              type="submit"
              variant="primary"
            >
              Definir nova senha
            </b-button>
          </b-form>
        </validation-observer>
      </b-card>
    </div>
  </div>

</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import {
  BButton,
  BCard,
  BCardText,
  BCardTitle,
  BForm,
  BFormGroup,
  BFormInput,
  BImg,
  BInputGroup,
  BInputGroupAppend,
  BLink,
} from 'bootstrap-vue'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BCard,
    BButton,
    BCardTitle,
    BCardText,
    BForm,
    BFormGroup,
    BInputGroup,
    BLink,
    BFormInput,
    BInputGroupAppend,
    BImg,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      userEmail: '',
      confirm_password: '',
      // eslint-disable-next-line global-require
      logo: require('@/assets/images/logo/logo.png'),
      password: '',
      // validation
      required,
      token: '',

      // Toggle Password
      password1FieldType: 'password',
      password2FieldType: 'password',
    }
  },
  computed: {
    password1ToggleIcon() {
      return this.password1FieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    password2ToggleIcon() {
      return this.password2FieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  methods: {
    togglePassword1Visibility() {
      this.password1FieldType = this.password1FieldType === 'password' ? 'text' : 'password'
    },
    togglePassword2Visibility() {
      this.password2FieldType = this.password2FieldType === 'password' ? 'text' : 'password'
    },
    validationForm() {
      this.$refs.simpleRules.validate()
        .then(async success => {
          if (success) {
            const data = {
              token: this.$route.params.token,
              password: this.password,
              password_confirmation: this.confirm_password,
            }
            const response = await this.$http.post('auth/confirm-password', data)
            if (!response.error) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Senha alterada com sucesso. Realize seu login',
                  icon: 'EditIcon',
                  variant: 'success',
                },
              })
              this.$router.push({ name: 'login' })
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'token para recuperação de senha expirado.',
                  icon: 'EditIcon',
                  variant: 'danger',
                },
              })
            }
          }
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
